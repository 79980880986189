import React, { UIEvent, useEffect, useRef, useState } from 'react';
import { NavigationItemProps } from 'components/Layouts/Headers/HeaderComponents/HeaderNavigation/components/NavigationItem/NavigationItem.typed';

const useNavigationItem = (option: NavigationItemProps) => {
  const items = option.items;
  const hasSubmenu = !!items;
  const [submenuIsOpen, setSubmenuOpen] = useState(true);

  const handleClick = React.useCallback(
    (e: UIEvent) => {
      if (hasSubmenu) return;

      e.stopPropagation();
    },
    [hasSubmenu, option],
  );

  const submenuRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    const submenuElement = submenuRef.current;

    const observer = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const isHTMLElement = entry.target instanceof HTMLElement;
        if (isHTMLElement) {
          setSubmenuOpen(entry.target.offsetWidth > 0);
        }
      });
    });

    if (submenuElement) {
      observer.observe(submenuElement);
    }

    return () => {
      if (submenuElement) {
        observer.unobserve(submenuElement);
      }
    };
  }, []);

  return {
    submenuRef,
    items,
    hasSubmenu,
    setSubmenuOpen,
    submenuIsOpen,
    handleClick,
  };
};

export { useNavigationItem };
