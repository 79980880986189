import styled, { css } from 'styled-components';

import type { HeaderVariant } from 'components/Layouts/Headers/StandardHeader';
import { Tag } from 'components/Toolkit/Tags/Tag';

const Wrapper = styled.div`
  display: none;
  align-content: start;
  margin-right: auto;
  margin-left: ${({ theme }) => theme.spacing.M32};
  gap: ${({ theme }) => theme.spacing.M16};
  @media (min-width: 1100px) {
    display: flex;
  }
`;

const MainButtonText = styled.span`
  position: relative;
`;

export const getNavigationMainStyles = css<{
  variant: HeaderVariant;
}>`
  color: ${({ theme, variant }) =>
    variant === 'TRANSPARENT' ? theme.colors.WHITE : theme.colors.GREY_DARKER};
  width: 100%;
  padding: ${({ theme }) => theme.spacing.S8};
  margin: 0;
  ${({ theme }) => theme.fontSize.M16}
  cursor: pointer;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  :active {
    color: ${({ theme, variant }) =>
      variant === 'TRANSPARENT'
        ? theme.colors.GREY_LIGHT
        : theme.colors.GREY_DARK};
  }
`;

export const getNavigationMainButtonOpen = css<{
  variant: HeaderVariant;
}>`
  content: '';
  background-color: ${({ theme, variant }) =>
    variant === 'TRANSPARENT' ? theme.colors.WHITE : theme.colors.GREY_DARKER};
  position: absolute;
  left: 0px;
  display: block;
  height: 2px;
  width: 100%;
  bottom: -10px;
`;

const MainButton = styled.button<{
  isOpen: boolean;
  variant: HeaderVariant;
}>`
  ${getNavigationMainStyles}
  ${({ isOpen }) =>
    isOpen &&
    css`
      ${MainButtonText} {
        ::after {
          ${getNavigationMainButtonOpen}
        }
      }
    `};
`;

const MainLink = styled.a<{
  isOpen: boolean;
  variant: HeaderVariant;
}>`
  ${getNavigationMainStyles};
  display: block;
  text-transform: none;
  :hover {
    ${MainButtonText} {
      ::after {
        ${getNavigationMainButtonOpen}
      }
    }
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      ${MainButtonText} {
        ::after {
          ${getNavigationMainButtonOpen}
        }
      }
    `};
`;

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.S4};

  & > a {
    padding-right: 0;
  }
`;

type STagProps = {
  headerVariant: HeaderVariant;
};

const NewBadge = styled(Tag)<STagProps>`
  display: flex;
  height: 20px;
  align-items: center;
  box-sizing: border-box;
  ${({ theme }) => theme.fontSize.B12};
  border: 1px solid
    ${({ theme, headerVariant }) =>
      headerVariant === 'DEFAULT' ? theme.colors.GREY : theme.colors.WHITE};
  padding: ${({ theme }) => `${theme.spacing.S2} ${theme.spacing.S4}`};
`;

export { MainButton, MainButtonText, MainLink, Wrapper, LinkWrapper, NewBadge };
