import styled, { createGlobalStyle, css } from 'styled-components';
import { HeaderVariant } from 'components/Layouts/Headers/StandardHeader';
import { media } from 'helpers/breakpoints';
import Modal from 'react-modal';
import { withOpacity } from 'helpers/Theme/Theme';
import { Tag } from 'components/Toolkit/Tags/Tag';

const OverlayStyle = createGlobalStyle`
  .bottom-sheet-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    background-color:rgba(0,0,0,0) !important;
    transition: all 200ms ease-in;
    height: 100vh !important;
    width: 100%;
    z-index: 9950;

    &.ReactModal__Overlay--after-open {
      background-color: ${({ theme }) =>
        withOpacity(theme.colors.GREY_DARKER, 0.8)} !important;
      }

    &.ReactModal__Overlay--before-close {
      background-color:rgba(0,0,0,0) !important;
    }
  }
`;

const Dialog = styled(Modal)<{ height: string }>`
  position: fixed;
  right: 0;
  bottom: 0;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.WHITE};
  transition-duration: 150ms;
  transform: translate3d(100%, 0, 0);
  width: 100%;

  ${media.medium} {
    width: 480px;
  }

  .ReactModal__Overlay--after-open & {
    transform: translateX(0);
  }

  .ReactModal__Overlay--before-close & {
    transform: translateX(100%);
  }

  :focus {
    outline: none;
  }
`;

const DialogWrapper = styled.div`
  display: flex;
  align-content: start;
  margin-right: auto;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.M24};
`;

const DialogInner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const DialogBody = styled.div`
  position: relative;
  flex: 1 1 100%;
  height: 100%;
  overflow: auto;
`;

export const Close = styled.span`
  top: ${({ theme }) => theme.spacing.L56};
  right: ${({ theme }) => theme.spacing.M24};
  color: ${({ theme }) => theme.colors.GREY_DARKER};
  position: absolute;
  padding: 0;
  cursor: pointer;

  top: ${({ theme }) => theme.spacing.L56};
  right: ${({ theme }) => theme.spacing.M24};

  ${media.medium} {
    right: ${({ theme }) => theme.spacing.L48};
  }
`;

const MainButtonText = styled.span`
  position: relative;
`;

const getNavigationMainStyles = css<{
  variant: HeaderVariant;
}>`
  color: ${({ theme, variant }) =>
    variant === 'TRANSPARENT' ? theme.colors.WHITE : theme.colors.GREY_DARKER};
  width: 100%;
  margin: 0;
  ${({ theme }) => theme.fontSize.L18}
  cursor: pointer;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  padding: ${({ theme }) => theme.spacing.M16} 0;
`;

const Separator = styled.span`
  height: 1px;
  width: 100%;
  margin: 0 auto;
  display: block;
  background-color: ${({ theme }) => theme.colors.GREY_LIGHT};
`;

const MainLink = styled.a<{
  isOpen: boolean;
  variant: HeaderVariant;
}>`
  ${getNavigationMainStyles}
`;

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.S4};
`;

const NewBadge = styled(Tag)`
  display: flex;
  height: 20px;
  align-items: center;
  box-sizing: border-box;
  ${({ theme }) => theme.fontSize.B12};
  border: 1px solid ${({ theme }) => theme.colors.GREY};
  padding: ${({ theme }) => `${theme.spacing.S2} ${theme.spacing.S4}`};
`;

export {
  MainButtonText,
  MainLink,
  Separator,
  DialogWrapper,
  DialogBody,
  DialogInner,
  Dialog,
  OverlayStyle,
  LinkWrapper,
  NewBadge,
};
