import { ReactNode, useEffect } from 'react';
import dynamic from 'next/dynamic';
import styled from 'styled-components';
import axios from 'axios';

import { StandardHeader } from 'components/Layouts/Headers/StandardHeader';
import { Footer } from 'components/Layouts/Footer/Footer';
import { useUserContext } from 'contexts/UserContext';
import { ThemeTypes } from 'types/Theme.namespace';
import { GlobalSearchModalProps } from 'components/PageWrappers/SharedComponents/GlobalSearchModal/GlobalSearchModal';
import type { VerificationComponentsThatAreNotInitiallyVisibleProps } from 'components/Layouts/components/VerificationComponentsThatAreNotInitiallyVisible';
import { inAppMessagingApi } from 'api/inAppMessagingApi';
import { Notifications, IUnreadInAppMessageData } from 'types';
import getConfig from 'next/config';
import {
  generateDDRecommendedUserCookie,
  removeDDRecommendedUserCookie,
} from 'utils/ddRecommendedUserCookie';
import { useGlobalContext } from 'contexts/GlobalContext';

const GlobalSearchModal = dynamic<GlobalSearchModalProps>(
  () =>
    import(
      /* webpackChunkName: "GlobalSearchModal" */ 'components/PageWrappers/SharedComponents/GlobalSearchModal/GlobalSearchModal'
    ).then((mod) => mod.GlobalSearchModal),
  { ssr: false },
);

const VerificationComponentsThatAreNotInitiallyVisible =
  dynamic<VerificationComponentsThatAreNotInitiallyVisibleProps>(
    () =>
      import(
        './components/VerificationComponentsThatAreNotInitiallyVisible'
      ).then((mod) => mod.VerificationComponentsThatAreNotInitiallyVisible),
    { ssr: false },
  );

const InAppMessagingComponents = dynamic<{
  inAppMessageData: IUnreadInAppMessageData;
  cdnUrl: string;
}>(
  () =>
    import('./components/InAppMessagingComponents').then(
      (mod) => mod.InAppMessagingComponents,
    ),
  { ssr: false },
);

type HeaderVariant = 'DEFAULT' | 'TRANSPARENT';
interface DefaultLayoutProps {
  children: ReactNode;
  domain?: string;
  bg?: ThemeTypes.Colors;
  headerVariant?: HeaderVariant;
  headerHasSpacer?: boolean;
  notificationData?: Notifications;
  unreadInAppMessage?: string;
  vertical?: string;
}

const Wrapper = styled.div<{ bg: ThemeTypes.Colors }>`
  ${({ theme, bg }) => bg && `background-color: ${theme.colors[bg]}`}
`;

const {
  publicRuntimeConfig: { CDN_STATIC_ASSETS, DATA_REQUESTS_URL },
} = getConfig();

function DefaultLayout({
  children,
  domain = '',
  bg = 'OFFWHITE',
  headerVariant = 'DEFAULT',
  headerHasSpacer = false,
  notificationData = {
    unreadHistoryChecks: 0,
    unreadMessages: 0,
    totalUnread: 0,
  },
  unreadInAppMessage,
  vertical,
}: DefaultLayoutProps) {
  const {
    user,
    ddRecommendedUser,
    consentOptions: { functionalConsent },
    setIsInAppMessageSimpleModalOpen,
    setInAppMessageData,
    inAppMessageData,
  } = useUserContext();

  const { navigation } = useGlobalContext();

  useEffect(() => {
    if (functionalConsent === false) {
      document.cookie = removeDDRecommendedUserCookie();
    } else if (user?.id) {
      document.cookie = generateDDRecommendedUserCookie(user?.id);
    } else if (ddRecommendedUser) {
      document.cookie = generateDDRecommendedUserCookie(ddRecommendedUser);
    }
  }, [ddRecommendedUser, user, functionalConsent]);

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();
    const fetchMessageData = async function () {
      if (unreadInAppMessage === 'true') {
        try {
          const { data } = await inAppMessagingApi.getUnreadInAppMessageData(
            cancelTokenSource,
          );
          setIsInAppMessageSimpleModalOpen(true);
          if (data.notificationName === 'payment-drop-off') {
            /**
             * Why override the response here?
             * BE returns ctaUrl for 'payment-drop-off' as url to legacy payment page
             * Non-trivial change on BE to fix this and can't handle at nginx level
             * without breaking flow for apps. This is not ideal and if revisited by BE
             * the adId should be returned as its own field.
             */
            const adID = data?.ctaUrl?.split('/').pop();
            setInAppMessageData({
              ...data,
              ctaUrl: adID ? `/order/pay/payment/${adID}` : data.ctaUrl,
            });
          } else {
            setInAppMessageData(data);
          }
        } catch (_error) {}
      }
    };
    fetchMessageData();

    /*
     * Ensure modal does not appear and api call is cancelled
     * after user has navigated away from the page
     */
    return () => {
      setIsInAppMessageSimpleModalOpen(false);
      setInAppMessageData(undefined);
      cancelTokenSource.cancel();
    };
  }, [unreadInAppMessage]);

  return (
    <Wrapper bg={bg}>
      <StandardHeader
        baseUrl={domain}
        cdnUrl={CDN_STATIC_ASSETS}
        variant={headerVariant}
        headerHasSpacer={headerHasSpacer}
        vertical={vertical}
        {...(user && {
          username: user?.name,
          notificationCount: notificationData.totalUnread,
          unreadMessageCount: notificationData.unreadMessages,
          unreadHistoryCheckCount: notificationData.unreadHistoryChecks,
        })}
        navigation={navigation}
      />
      <GlobalSearchModal baseUrl={domain} cdnUrl={CDN_STATIC_ASSETS} />
      {unreadInAppMessage === 'true' && inAppMessageData && (
        <InAppMessagingComponents
          cdnUrl={CDN_STATIC_ASSETS}
          inAppMessageData={inAppMessageData}
        />
      )}
      {user && (
        <VerificationComponentsThatAreNotInitiallyVisible
          cdnUrl={CDN_STATIC_ASSETS}
        />
      )}
      {children}
      <Footer
        baseUrl={domain}
        cdnUrl={CDN_STATIC_ASSETS}
        isLoggedIn={Boolean(user?.name)}
        dataRequestsUrl={DATA_REQUESTS_URL}
      />
    </Wrapper>
  );
}

export default DefaultLayout;
