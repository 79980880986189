import { useCallback, useMemo, useRef, useState } from 'react';
import { useClickOutside } from 'hooks/useClickOutside';

const useNavigation = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [navigationIsOpen, setNavigationOpen] = useState(false);

  const toggleNavigation = useCallback(
    () => setNavigationOpen((state) => !state),
    [],
  );
  const closeNavigation = useCallback(() => setNavigationOpen(false), []);

  const childrenProps = useMemo(() => {
    return {
      isOpen: navigationIsOpen,
      setNavigationOpen: setNavigationOpen,
    };
  }, [navigationIsOpen, toggleNavigation]);

  useClickOutside({
    active: navigationIsOpen,
    ref: containerRef,
    onClickOutside: () => setNavigationOpen(false),
  });

  const rootMenuRef = useRef<HTMLUListElement>(null);

  return {
    childrenProps,
    navigationIsOpen,
    rootMenuRef,
    containerRef,
    closeNavigation,
  };
};

export { useNavigation };
